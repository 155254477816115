<template>
    <div class="rent-card">
        <div>
            <h1>{{ title }}</h1>
            <p class="line">{{ text }}</p>
        </div>
        <img class="emoji" src="../assets/survey.svg" alt="" />
        <ButtonCTA :cta="buttonCtaText" :href="buttonCtaHref" :showRouterButton="false" />
    </div>
</template>

<script>
import ButtonCTA from './ButtonCTA.vue'

export default {
    name: 'VentYourRentCard',
    props: {
        title: String,
        text: String,
        buttonCtaText: String,
        buttonCtaHref: String,
        picture_src: String
    },
    components: {
        ButtonCTA
    }
}
</script>

<style scoped>
.rent-card {
    border: 1px solid var(--dark-red-color);
    border-radius: 16px;
    padding: 4vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 5vh 0;
}

.line {
    width: 60%;
    margin: 0 auto;
    display: inline-block;
    text-align: left;
}

.emoji {
    width:30%;
    height: auto;
    padding: 4vw 0;
}

/* mobile */
@media only screen and (max-width: 768px) {
    .rent-card {
    padding: 8vw;
}
    .line {
        width: 100%;
    }
    .emoji {
        width: 60%;
    }
}

</style>