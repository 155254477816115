<template>
    <div class="">

        <LandingText title="TCD Renters’ Solidarity Network" :state="shouldShowSubtitle ? 'show' : 'hide'"
            txt="Are you tired of Trinity making millions of euros in profit while you have little rights in campus accommodation? Rise up and join the fight! TCD Renters’ Solidarity Network aims to coordinate campaigns around the collective issues we face. We campaign to challenge Trinity’s restrictive and paternalistic overnight guests policy, advocate for free laundry machines and stand up against unaffordable rent, as well as to coordinate Palestinian solidarity through initiatives such as flag drops. Join us. "
            class="header-text"
            :img_class="homepage ? 'homePic' : 'default'"/>

         <HomeBanner /> 
    </div>

    <div class="main-container">
        <div class="home-cards-container">
            <HomeCard class="card" title="About Us"
                smallText="As a student-run collective, TCD Renters Solidarity Network is committed to its mission of representing, protecting, and defending our fellow student renters in the face of institutional injustice and costly rents. We seek to ensure every student is able to secure rent that is safe, fair and affordable."
                buttonText="Meet us" 
                buttonLink="/about"
                />
            <HomeCard class="card" title="News & Events"
                smallText="See the latest news coverage and media appearances of our Network. Relevant articles on housing are also regularly posted."
                 buttonText="Discover new things"
                 buttonLink="/news"
                picture_src="hp-news.svg" 
                />
         
        </div>

        <div class="home-cards-container second">
            <HomeCard class="card" title="What do I need to know?"
                smallText="Overwhelmed by the renting process? Looking for more information on your tenant rights and how to avoid scams? Look no further: our resources page is full of answers to your questions, whether you're renting for the first time, renewing an accommodation contract, or moving to Irelend."
                buttonText="Learn more" 
                buttonLink="/resources"
                picture_src="hp-resources.svg"/>
            <HomeCard class="card" title="What can I do?"
                smallText="Join our network and spread the word! From supporting us online through petition-signing to rallying with us in-person, there are lots of ways to support your student renter community."
                buttonText="Engage"
                buttonLink="/actions"/>
         
        </div>
    </div>
</template>

<script>
import LandingText from '../components/LandingText.vue';
import HomeCard from '../components/HomeCard.vue';
import HomeBanner from '../components/HomeBanner.vue';

export default {
    name: 'HomePage',
    components: {
        LandingText,
        HomeCard,
        HomeBanner
    },
    data() {
        return {
            shouldShowSubtitle: false,
            homepage: true,
        };
    }
}
</script>

<style scoped>

.header-text {
    padding: 10vh 9vw 5vh 9vw;
    width: 82vw
}
.jumbotron {
    background-color: #ffedca;
}

.home-cards-container {
    display: grid;
    grid-template-columns: 1fr 2fr;
    margin-top:10vh;
    grid-gap: 5vh;
}

.home-cards-container.second {
    margin-bottom: 10vh;
    margin-top:5vh;
    grid-template-columns: 2fr 1fr;
}




@media only screen and (max-width: 600px) {

    .jumbotron {
        padding-bottom: 30vh;
    }

    .home-cards-container,
    .home-cards-container.second {
        grid-template-columns: 1fr;
    }

}
</style>