<template>
    <div class="jumbotron">
        <div class="l-left-side">
            <h1>{{ title }}</h1>
            <h2 :class="state">{{ subTitle }}</h2>
            <p class="line">{{ txt }}</p>
        </div>

        <div class="l-right-side">
            <EventCarousel class="events"  v-if="news"/>
            <img v-else :class="img_class" :src="resolve_img_url(picture_src)" :alt="altText">
        </div>
    </div>
</template>
<script>
import EventCarousel from '../components/EventCarousel.vue';

export default {
    name: 'LandingText',
    components: {
        EventCarousel,
    },
    props: {
        title: {
            type: String,
            default: 'No title'
        },
        subTitle: {
            type: String,
            default: 'No sub-title'
        },
        txt: {
            type: String,
            default: 'No text'
        },
        state: {
            type: String,
            default: 'show'
        },
        img_class: {
            type: String,
            default: 'default'
        },
        picture_src: {
            type: String,
            default: 'homeImage.png'
        },
        altText: {
            type: String,
            default: 'picture'
        }, 
        news: Boolean
    },
    methods: {
        resolve_img_url: function (path) {
            let images = require.context('../assets/', false)
            return images("./" + path)
        }
    }
}


</script>

<style scoped>
.jumbotron {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1.3fr;
    grid-template-rows: auto;
    grid-gap: 5vw;
    align-items: center;
    padding: 10vh 0 2vh 0;
    width: 100%;
}

.action.jumbotron {
    padding: 20vh 0;
}

.l-left-side {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto auto;
    justify-items: start;
    align-items: start;

}

.default {
    width: 100%;
}

.events {
    width: calc(100% + 9vw);
    transform: translateX(9vw);
    overflow-x: hidden;
    height: fit-content;
}

.action .default {
    position: absolute;
    top: -17vh;
    right: -9vw;
    width: 110%;
}

.homePic {
    position: absolute;
    right: -9vw;
    bottom: -5vh;
    width: 120%;
}

.l-right-side {
    position: relative;
    display: grid;
    justify-items: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

h1,
h2 {
    margin: 0;
}

h2 {
    margin-top: 3vh;
}

.show {
    display: block;
}

.hide {
    display: none;
}

img {
    width: 100%;
}

.carousel {
    display: flex;
    align-items:flex-end;
    width: 100%;
    gap: 20px;
}

/* mobile */
@media only screen and (max-width: 1024px) {
    .jumbotron {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;
        grid-gap: 5vw;
        align-items: center;
        padding: 5vh 0;

    }
    .action.jumbotron {
    padding: 5vh 0;
}
    .action .default {
    position: relative;
    top: 0;
    right: 0;
    width: 100%;
}
.homePic {
    position: absolute;
    right: -9vw;
    bottom: -30vh;
    width: 130%;
}
.events {
    width: calc(100% + 9vw);
    transform: translateX(4.5vw);
    height: 100%;
}
}
</style>