<template>
    <div class="event-carousel">
        <div class="cards" ref="cards">
            <div class="event-card" v-for="evt in eventDetails" :key="evt.id"
                :class="{ 'active': evt.id === activeEventId }" @click="focusEvent(evt.id); activateEvent(evt.id)">
                <img class="event-image" :src="evt.imageSrc" :alt="evt.title" />
                <div class="event-details">
                    <h3 class="evtTitle">{{ evt.title }}</h3>
                    <p> A brief description of the event.</p>
                    <p class="evtTime">{{ evt.time }}</p>
                    <p class="evtLocation">{{ evt.location }}</p>
                </div>
            </div>
        </div>

        <div class="navigation-dots">
            <span v-for="evt in eventDetails" :key="evt.id" :class="{ 'active': evt.id === activeEventId }"
                @click="focusEvent(evt.id); activateEvent(evt.id)"></span>
        </div>
    </div>
</template>

<script>
export default {
    name: "EventCarousel",
    data() {
        return {
            activeEventId: 0,
            eventDetails: [
                {
                    id: 0,
                    title: "Protest for lower rent",
                    imageSrc:
                        "https://www.irishtimes.com/resizer/poFocdcsUvVp_IUc5ywrzsPUuNU=/1440x0/filters:format(jpg):quality(70)/cloudfront-eu-central-1.images.arcpublishing.com/irishtimes/ZDHUYNUERRGT5OWBZGPGQR3OUA.jpg",
                    time: "17/02/24 Sat 14:00",
                    location: "Main Square",
                },
                {
                    id: 1,
                    title: "Block the Book of Kells",
                    imageSrc:
                        "https://www.irishtimes.com/resizer/2dFNJYcbqR2AXGsLtarkjKO_KhI=/1440x0/filters:format(jpg):quality(70)/cloudfront-eu-central-1.images.arcpublishing.com/irishtimes/O5GANRZW7JBCZLFPWKOTYDCLOE.jpg",
                    time: "20/11/23 Fri 15:00",
                    location: "Book of Kells",
                },
                {
                    id: 2,
                    title: "Flyer distribution",
                    imageSrc:
                        "https://www.thesun.ie/wp-content/uploads/sites/3/2020/02/NINTCHDBPICT000564557144.jpg",
                    time: "22/01/24 Mon 16:00",
                    location: "Art Building",
                },
            ],
        };
    },
    methods: {
        activateEvent(eventId) {
            this.activeEventId = eventId === this.activeEventId ? 0 : eventId;
        },

        focusEvent(index) {
            this.activeIndex = index;
            const offsetLeft = this.$refs.cards.children[index].offsetLeft;
            const cardWidth = this.$refs.cards.children[index].offsetWidth;
            // Calculate the direction of movement based on the index of the active card
            const direction = index > this.activeIndex ? 1 : -1;
            // Calculate the adjusted offset based on the direction of movement
            let offsetWithAdjustment;

            if (index === 0 || this.activeEventId === this.activeIndex) {

                // Moving right from last image back to the first image
                offsetWithAdjustment = 1 * window.innerWidth / 100;
            } else {
                offsetWithAdjustment = offsetLeft - (12 * window.innerWidth / 100 * direction) + (cardWidth * direction);
            }
            this.$refs.cards.style.transform = `translateX(-${offsetWithAdjustment}px)`;
        }



    },
};
</script>

<style scoped>
.event-carousel {
    display: grid;
    /* flex-direction: column; */
    grid-template-rows: auto auto;
    grid-template-columns: auto;
    height: 100%;
    width: 100%;
    grid-gap: 3vh;
    position: relative;
    overflow: hidden;

    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
}

.cards {
    display: flex;
    align-items: center;
    gap: 1vw;
    padding: 1vw;

    transition: transform 0.5s ease;
}

/* card */
.event-card {
    border-radius: 20px;
    border: 1px solid var(--dark-grey-color);
    filter: grayscale(100%) blur(2px);
    background-color: var(--background-color);
    color: var(--black-color);
    cursor: pointer;
    aspect-ratio: 1.3/0.5;
    width: 35%;
    height: 90%;
    
    /* flex: 0 0 auto; */
    transition: all 0.3s ease;
}

.event-card:hover {
    filter: grayscale(0%) blur(0);
}



.event-card.active {
    filter: grayscale(0%) blur(0);
    width: 40%;
    height: 100%;
    
}
h3 {
    font-size: 20px;
    margin: 0;
}
.event-details p {
    margin: 0.2vw 0;
    font-size: 16px;
}


.event-card.active h3 {
    font-size: 24px;
}

.event-card.active p {
    font-size: 18px; 
}


.event-details {
    display: flex;
    flex-direction: column;
    padding: 1.5vw;

}



.event-image {
    width: 100%;
    height: auto;
    border-radius: 20px 20px 0 0;
    margin: 0;
    filter: sepia(100%) hue-rotate(-50deg) saturate(300%) brightness(80%);
}



.evtTime,
.evtLocation {
    font-size: 18px;
    margin: 0 0 10px 0;
}

/* navigation  */

.navigation-dots {
    display: flex;
    margin-left: calc(50% - 4vw);
}

.navigation-dots span {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: var(--dark-grey-color);
    opacity: 0.4;
    margin: 0 5px;
    cursor: pointer;
}

.navigation-dots span.active {
    opacity: 1;
    
}

@media (max-width: 768px) {
    .event-carousel {
        width: calc(100%+9vw);
        gap: 10vw;
    }

    .cards {
        gap: 5vw;
    }

    /* card */
    .event-card {
        width: 40%;
        height: 80%;
    }

    .event-card.active {
        width: 60%;
        height: 100%;
    }

    .navigation-dots {
        display: flex;
        margin-left: calc(41vw - 9vw);
    }

    .navigation-dots span {
        width: 20px;
        height: 20px;
    }

    h3 {
    font-size: 16px;
}
.event-details p {
    margin: 0.3vw 0;
    font-size: 12px;
}


.event-card.active h3 {
    font-size: 19px;
}

.event-card.active p {
    font-size: 15px; 
}

    .evtTime,
    .evtLocation {
        font-size: 16px;
    }

    .event-details {
        padding: 4vw;
    }



}
</style>
