<template>
    <div class="petition-container">
      <div class="l-left-side">
        <h1>{{ title }}</h1>
        <h2 :class="state">{{ subTitle }}</h2>
        <div class="p-box">
          <div class="progress-bar">
            <div class="progress" :style="{ width: progressWidth }"></div>
          </div>
          <p>{{ signaturesLeft }}/{{ signaturesGoal }}</p>
        </div>
        <p class="line">{{ txt }}</p>
        <ButtonCTA :cta="buttonCtaText" :href="buttonCtaHref" :showRouterButton="false" />
      </div>
      <img class="default" :src="resolve_img_url(picture_src)" :alt="altText">
    </div>
  </template>
  
  <script>
  import ButtonCTA from './ButtonCTA.vue'
  
  export default {
    name: 'PetitionCard',
    components: {
      ButtonCTA,
    },
    props: {
      title: {
        type: String,
        default: 'No title'
      },
      subTitle: {
            type: String,
            default: 'No sub-title'
        },
      txt: {
        type: String,
        default: 'No text'
      },
      signaturesGoal: {
        type: Number,
        default: 600
      },
      signaturesLeft: {
        type: Number,
        default: 554
      },
      picture_src: {
        type: String,
        default: 'homeImage.png'
      },
      altText: {
        type: String,
        default: 'picture'
      },
      buttonCtaText: String,
      buttonCtaHref: String,
    },
    computed: {
      progressWidth() {
        return `${(this.signaturesLeft / this.signaturesGoal) * 100}%`;
      }
    },
    methods: {
      resolve_img_url(path) {
        let images = require.context('../assets/', false)
        return images("./" + path)
      }
    }
  };
  </script>
  
  <style scoped>
  h1 {
    margin: 2vh 0;
  }
  
  .petition-container {
    background-color: var(--light-grey-color);
    position: relative;
    padding: 10vh 9vw;
    width: 91vw;
    left: -9vw;
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    align-items: center;
    grid-gap: 3vw;
  }
  
  img.default {
    width: auto;
    height: calc(100% + 20vh);
    max-width: 100%;
    object-fit: cover;
  }
  
  .p-box {
    display: flex;
    gap: 2vw;
    align-items: center;
  }
  
  .progress-bar {
    width: 70%;
    height: 8px;
    background-color: var(--dark-grey-color);
    border-radius: 10px;
  }
  
  .progress {
    height: 100%;
    background-color: var(--light-red-color);
    border-radius: 10px;
  }
  /* mobile */
@media only screen and (max-width: 768px) {
    .petition-container {
    padding: 5vh 9vw 0 9vw;
    grid-template-columns: auto;
    grid-template-rows: 1fr auto;
    width: 82vw;
  }
  img.default {
    width: auto;
    height:70vh ;
    transform: translateX(-9vw);
    max-width:calc(100% + 18vw) ;
    object-fit: cover;
    object-position: center;
  }
  .l-left-side {
    max-width:100% ;
  }
}
  </style>
  