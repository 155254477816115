<template>
    <div class="actions-card">
        <div class="content">
            <h2>{{ title }}</h2>
            <p class="tag">{{ tag }}</p>
            <p>{{ text }}</p>
        </div>
        <div class="button-container" >
            <ButtonCTA :cta="buttonCtaText" :href="buttonCtaHref" :showRouterButton="false" />
        </div>
    </div>
</template>

<script>
import ButtonCTA from './ButtonCTA.vue'

export default {
    name: 'ActionCard',
    props: {
        title: String,
        tag: String,
        text: String,
        buttonText: String,
        buttonCtaText: String,
        buttonCtaHref: String,
    },
    components: {
        ButtonCTA
    }
}
</script>

<style scoped>
.actions-card {
    border: 1px solid var(--black-color);
    border-radius: 15px;
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.tag {
    color: var(--dark-red-color);
    border: 1px solid var(--dark-red-color);
    padding: 0.5vw 2vw;
    border-radius: 16px;
    margin-bottom: 5vh;
    width: fit-content;
}

.text {
    font-size: 22pt;
    margin-bottom: 20px;
}

.button-container {
    display: flex;
    justify-content: flex-end;
}
/* mobile */
@media only screen and (max-width: 768px) {

    .tag {
    padding: 1vw 4vw;
}
}

</style>
