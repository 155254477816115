<template>
    <div class="main-container">
        <div class="head-container">
            <LandingText title="News & Events" subTitle="What is happening?"
                txt="Explore our latest press releases, statements, and media mentions, and join the fight in person by checking out our upcoming and past events."
                :news="true" />

        </div>
        <ArticleGrid />
    </div>
</template>

<script>
import LandingText from '../components/LandingText.vue';
import ArticleGrid from '../components/ArticleGrid.vue';

export default {
    name: 'NewsPage',
    components: {
        LandingText,
        ArticleGrid
    }
}
</script>