<template>
    <div class="main-container">
        <!-- first block -->
        <LandingText title="About us" subTitle="Who are we?"
            txt="TCD Renters’ Solidarity Network is a grassroots group of Trinity student tenants that aims to coordinate campaigns around the collective issues we face. We campaign to challenge the restrictive and paternalistic overnight guests policy, advocate for free laundry machines and stand up against unaffordable rents, as well as to coordinate Palestinian solidarity such as flag drops. We organise direct actions to meet our demands. This includes protests, petitions, and legal pressure, as well as an organised rent strike. "
            picture_src="groupPicture.png" altText="Students holding a 'Cut the Rents' poster." />


        <h1>Demands</h1>
        <!-- second block -->
        <h3>The following are TRSN’s core demands These are directed at the College and reflect the most pressing issues
            faced by TCD renters. <span class="demand-cta"> Do you have an issue that deserves attention? Send us an
                email, or submit a <a target="_blank"
                    href="https://docs.google.com/forms/d/e/1FAIpQLSf6LspWwrxWiaTGDAxq-_5mdgZra7aG7S1A3DyShMoH_UMcdg/viewform">Vent
                    Your
                    Rent</a> here!</span> </h3>
        <div id="demands" class="l-block">
            <ListPopup :demands="demands" :defaultDemand="defaultDemand" :demandTexts="demandTexts" />
        </div>
        <div>
            <VentYourRentCard :title="'Join us!'"
                text="Our closed WhatsApp group ensures privacy in mobilisation and community support. This online space provides us a digital avenue to organise campaigns and collective action, share stories and advice, and meet other like-minded students advocating for change."
                buttonCtaText="Fill the form"
                buttonCtaHref="https://docs.google.com/forms/d/e/1FAIpQLSc2lCUYc281K3UqEfMRO59H1zQsJc4fmjIykSAQFfK5cHtl2g/viewform" />



        </div>



        <!-- fourth block -->
        <h1>FAQ</h1>
        <div class="l-block">
            <ListPopup :demands="questions" :defaultDemand="defaultDemand" :demandTexts="answers" />
        </div>
    </div>
</template>

<script>
import LandingText from '../components/LandingText.vue';
import ListPopup from '../components/ListPopup.vue';
import VentYourRentCard from '../components/VentYourRent.vue';

export default {
    name: 'AboutPage',
    components: {
        LandingText,
        ListPopup,
        VentYourRentCard
    },
    data() {
        return {
            demands: ['Abolish Overnight Guests Policy ', '30% Rent Reduction', 'Cap Disability Applicant Students To Pearse Street Rents', 'Rent Refunds for Each Day with Serious Maintenance Issues', 'Free Laundry Machines', 'Staff Visits To Be Communicated and Announced'],
            defaultDemand: 0,
            demandTexts: [
                `The policy of not letting students sign in guests after 11pm at Halls, and 12am on campus disproportionately limits the rights and autonomy of student residents. It is a paternalistic, dangerous and hypocritical policy.
      Firstly, it is paternalistic because it does not treat students as adults capable of making their own choices.
      Secondly, it is dangerous because someone might need to sleep at a resident’s place, such as in cases where they miss their public transport and cannot get home. Rejecting them from a safe place to sleep is unacceptable. It makes no sense in terms of controlling numbers because overnight guests can simply come on campus before midnight - as such, in any case, this policy is ineffective and only serves to put us in danger.
      Thirdly, it is hypocritical because the same policy does not apply to staff living on campus, such as lecturers or Fellows. 
      The issue has been raised by multiple student residents over a number of years, and as such we immediately call on College to change the policy to allow student residents to sign in guests any time.`,
                'A 30% reduction in campus rents is essential, considering 85% of students find current housing costs unaffordable, which not only exacerbates financial stress but could also detrimentally impact their academic performance and mental well-being. Lowering rents would make higher education more accessible, enabling a wider range of students to focus on their studies without the added burden of excessive housing costs, thereby fostering a more inclusive and supportive academic environment.',
                'The issue of accessible rooms being more expensive poses a significant concern for people with disabilities, highlighting an inequality that contradicts principles of inclusivity and equal opportunity. Charging higher rates for accessible rooms inadvertently penalizes individuals for their physical needs, exacerbating the financial burden on those who may already face additional expenses related to their disabilities. Therefore, we are asking to cap the prices for people with disabilities at the Pearse Street rents. ',
                'Implementing rent refunds for days affected by serious maintenance issues is a fair and equitable policy that holds housing providers accountable while ensuring students are not financially disadvantaged due to circumstances beyond their control. This approach incentivizes prompt and effective resolution of maintenance problems, improving living conditions and student satisfaction. ',
                'Offering free laundry facilities instead of charging 7.5 euros per wash and dry with Circuit Laundry can significantly alleviate financial strain on students, who often operate on tight budgets. Making laundry a basic, included amenity in student housing not only supports students financial well-being but also ensures equitable access to essential services, fostering a more inclusive and supportive living environment on campus.',
                'Better communication and advance announcements of staff visits are essential for respecting students privacy and schedules, minimizing disruptions, and fostering a more respectful campus environment.'
            ],
            questions: ['What is a rent strike?', 'Who is organising this rent strike?', 'Who would be in charge of the rent strike?', 'Has a rent strike been done before?', 'Why is the threshold to start the rent strike 100?', 'What are the consequences of this, and what plans are in place to mitigate them?', 'What is cashflow disruption?', 'What are students saying about overnight guests policy?', 'How much does Trinity rent cost and how much has it increased by? ', 'How much is Circuit Laundry at Trinity? ', 'How can I take part? And if I join, do I have to take part in the rent strike?', 'What if I have an issue with Trinity rent that isn’t listed in your demands?'],
            answers: [
                `A rent strike involves a collective of persons making a deliberate decision to withhold rent payments from their landlord for a certain period of time, therefore breaching the central tenet of the housing contract they have with their landlord. It is a tactic people use to protest against conditions seen as unfair, such as expensive accommodation, the overnight guests policy or the fact that we pay extortionate sums to wash and dry our clothes on campus and at Halls. Rent strikes, like other types of protests, focus on collective action - multiple people coming together and making a pact to strike at the same time.`,
                "The rent strike is low-risk and low-effort. The TCD Renters' Solidarity Network, which has more than 100 members and is supported by the TCDSU, has researched and planned it inside out and there is not a single risk or consequence we are not aware of or prepared for. This research includes taking legal advice from the Students' Union solicitor.",
                'Decisions will be taken by the rent strikers themselves in a democratic way.',
                'Yes! Starting in 2020, students in more than 50 universities in the U.K. went on rent strike in their student accommodations, and secured millions of euros worth of concessions, namely rent reductions up to 30%.',
                'In the University of Manchester around 8000 students live in student accommodation, and in 2020, 350 (5%) of those went on rent strike. We have 1800 students living on campus and in Halls, and the equivalent 5% would be around 100 students. This gives us safety in numbers, and we can act as a collective in standing up to College.',
                'We have taken legal advice on this. By not paying your rent, you are breaching your contract. This is an administrative matter for the College, not a disciplinary one, so you cannot be brought before the Junior Dean. It is also not a criminal offense, but a civil dispute. The College will apply penalties, in the same way as it would apply penalties for not paying student fees, library charges or being late on rent. This means unless you repay the rent in time, you will not see your results and will not be able to graduate and late rent charges may apply. The College may also choose to evict you after 2 months of non-payment. The way the rent strike is conducted makes it low-risk and low-effort. The money that you are not spending on rent will have to be put aside for eventual repayment before any of the penalties apply. The same goes for anyone at risk of eviction. Once you have repaid the rent, there is no risk. The point of the rent strike is cash flow disruption, not permanent withdrawal of money. What also helps us on the rent strike is that the College is afraid of bad press - and if they begin threatening us with penalties, we can always protest, occupy offices, and create bad press for them.',
                'The point is to disrupt the cash flow of the institution, which causes serious problems for them, in addition to the bad press that it generates.',
                `"I have had difficulties when coming home from nights out in letting a friend stay when they are unable to get home safely via public transport/taxi. The sign in cut off time doesn't allow for any changes of plan so it can leave people stranded without a place to stay when campus should be a refuge for them." "I was initially refused having my boyfriend visit me and stay with me during a very difficult time for me because it was over four days. I've yet to hear back from the Junior Dean if it's allowed and I find it crazy that it's not automatically permissible for me to have my loved ones stay with me when I need them "The policy is demeaning and unfair. I've had situations where I've walked a friend of mine home as she missed her last bus, and would have otherwise stayed with me. Dublin is a dangerous city - last year, I was attacked on the streets walking home, and I lived in the city centre. I can't imagine how terrifying it is for women. In many cases, I've known women walking home because they can't afford a taxi, and can't stay on their friend's sofa in college. It's a common situation, and leads to people taking or being forced to take extraordinary risks. It's only a matter of time before someone gets attacked after walking home when they could have stayed with their friend on campus." "During the holiday and when the University was closed and we could not have guests stay the night (as the form was closed), I suffered from a lot of depression due to previous bereavement. I think even during breaks we should be allowed to have guests over and stay with us. I don't understand why it’s not allowed because I was struggling with being in the flat alone the entire break.”`,
                "With a commitment to 'foster a more diverse and inclusive student population' as the first target in Trinity's 2020-2025 strategic plan, it is imperative that College actively remove barriers to entry. For the 1,900 or so students that live in our different accommodations, it is imperative that they are not priced out of education through unaffordable rents. We know that across Ireland, 15,000 students are in rent or fee debt to their institutions, as well as this we know that 55% of students work to afford College and that 88% of students worry about their finances. Students should feel they are supported by their College in order to excel academically, and should not be treated like a commercial revenue source. Rents have increased by 30-35% since 2015, in some cases by 60%, and 93% of students find these rents unaffordable. According to the TCDSU Housing Survey students find 85% of campus accommodations unaffordable. As such, there is an onus on the College to not put students in further financial distress and to create an inclusive environment.",
                'It costs on average €7.5 per wash and dry.',
                'Sign up via the link to our renters’ union to join our campaigns. You do not have to commit to anything. You can actively choose to participate or not participate as much as you like.',
                "Please, send us an email. We'd love to hear from you! Alternatively use this form to Vent Your Rent—every story helps build out our campaigns and our case against the school."
            ]

        }
    }
};
</script>

<style scoped>
h1 {
    text-align: center;
    padding-top: 5vw;
}

.demand-cta {
    font-family: 'TASA-Text-regular';
    font-weight: 400;
    font-style: italic;
}

.demand-cta a {
    color: var(--light-red-color);
}
</style>
