<template>
  <div id="app">

    <div class="tag-box">
      <button class="tagsBtn" v-for="(category, index) in categories" :key="index"
        :class="{ 'active': activeTab === index }" @click="activeTab = index">
        {{ category.name }}
      </button>
    </div>

    <div class="links">
      <ul class="linkList">
        <li class="link" v-for="link in activeLinks" :key="link.text">
          <a :href="link.url">{{ link.text }}</a>
          <span><font-awesome-icon :icon="['fas', 'arrow-right']"/> </span>
        </li>
      </ul>

    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      activeTab: 0, // The index of the currently active tab
      categories: [
        {
          name: 'Find Accommodations',
          links: [
            { text: 'Daft.ie', url: 'https://www.daft.ie/student-accommodation/' },
            { text: 'Residential Tenancies Board', url: 'https://www.rtb.ie/registration-and-compliance/rights-and-responsibilities/renting-in-college' },
            { text: 'Homestays in Dublin', url: 'https://www.homestay.com/ireland/dublin' },
            { text: 'Rent.ie', url: 'https://www.rent.ie/student-accommodation/' },
            { text: 'Student House', url: 'https://studenthouse.com/' },
            { text: 'Hosting Power', url: 'https://app.hostingpower.ie/' },
            { text: 'MyHome.ie', url: 'https://www.myhome.ie/' },
            { text: 'Spot A Home', url: 'https://www.spotahome.com/' },
            { text: 'CollegeCribs.ie', url: 'https://www.collegecribs.ie/' },
            { text: 'Yugo Student Living', url: 'https://yugo.com/en-gb/service/search/128630?query=dublin' },
            { text: 'Fresh Student Accomodation', url: 'https://www.thisisfresh.com/student/dublin/apartments' }
          ]
        },
        {
          name: 'Move from Abroad',
          links: [
          { text: 'InternationalStudents.ie Accommodation Guide 1', url: 'https://www.internationalstudents.ie/info-and-advice/practical-information/accommodation-guide' }, 
          { text: 'International Student Accommodation (ISA)', url: 'https://isaccommodation.com/' }, 
          { text: 'Irish Immigration Information', url: 'https://www.irishimmigration.ie/coming-to-study-in-ireland/' }, 
          { text: 'TCD International Student Accommodation FAQ (2023)', url: 'https://www.tcd.ie/students/assets/pdf/FAQ_JAN_2023_Version.pdf' }
          ]
        },
        {
          name: 'Special Demands',
          links: [
          { text: 'Housing Resources for Individuals with Disabilities', url: 'https://inclusionireland.ie/resources/housing/' }, 
          { text: 'Irish Wheelchair Association Resources', url: 'https://www.iwa.ie/services/accessible-housing/think-ahead-think-housing/' }, 
          { text: 'Bringing Pets to Ireland', url: 'https://dublin.ie/live/relocating-to-dublin/bringing-your-pet/' }, 
          { text: 'Moving to Ireland with Family', url: 'https://kiwieducation.com/ie/immigration/moving-to-ireland-with-a-family/' }, 
          { text: 'Information on Family Dependents | Irish Immigration', url: 'https://www.irishimmigration.ie/my-situation-has-changed-since-i-arrived-in-ireland/family-dependents/' }
          ]
        },
        
          {
  name: 'Tenant Rights',
  links: [
    { text: "Threshold's Guide to Student Renter Rights", url: 'https://threshold.ie/faq/student-rental-rights/' }, 
    { text: 'Finance, Accommodation and Rights Guide', url: 'https://usi.ie/wp-content/uploads/2017/08/USI-and-RTB-Finance-and-Accommodation-Guide-2017.pdf' },
    { text: 'Residential Tenancies Board', url: 'https://www.rtb.ie/' },
    { text: 'Residential Tenancies (Amendment) Act 2019', url: 'https://data.oireachtas.ie/ie/oireachtas/act/2019/14/eng/enacted/a1419.pdf' },
    { text: 'Residential Tenancies (No. 2) Act 2021', url: 'https://www.irishstatutebook.ie/eli/2021/act/17/enacted/en/html' }
  ]
},
{
  name: 'Avoid Scams',
  links: [
    { text: "CCPC's Tips for Avoiding Rental Accommodation Scams", url: 'https://www.ccpc.ie/consumers/money/scams/rental-accommodation-scams/' }, 
    { text: 'How to Spot and Avoid Rental Scams in Ireland', url: 'https://secureprop.com/how-to-spot-and-avoid-rent-scams-in-ireland/' }, 
    { text: 'Advice from the Gardai', url: 'https://www.garda.ie/en/about-us/our-departments/office-of-corporate-communications/press-releases/2023/august/an-garda-siochana-student-accommodation-fraud-warning-august-2023.html' }
  ]
}

      ],
    }
  },
  computed: {
    // Compute the links for the active tab
    activeLinks() {
      return this.categories[this.activeTab].links;
    }
  }
};
</script>

<style scoped>
.linkList {
  margin: 8vh 0 15vh 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 5vh;
  column-gap: 2vw;
}

.link {
  display: flex;
  width: 30%;
  justify-content: space-between;
}

.link span {
  margin-left: 2vw;
}
li.link {
  width: 100%;
}

.links ul {
  list-style: none;
  padding: 0;
}

.links li a:link {
  text-decoration: none;
  color: var(--black-color);
}

.links li:hover {
  color: var(--light-red-color);
  text-decoration: none;
}
/* mobile */
@media only screen and (max-width: 768px) {
  .linkList {
  grid-template-columns: 1fr;
}

.link {
  width: 100%;
}
  
}
</style>
