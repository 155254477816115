<template>

    <router-link v-if="showRouterButton" :to="link">
      <button :class="state">
        {{ cta }}
        <font-awesome-icon class="icon" v-if="showIcon" :icon="['fas', 'arrow-right']" />
      </button>
    </router-link>

    <a v-if="!showRouterButton" :href="href" target="_blank">
    <button :class="state">
        {{ cta }}
        <font-awesome-icon class="icon" v-if="showIcon" :icon="['fas', 'arrow-right']" />
      </button>
  </a>
   
  </template>
  
  <script>

  export default {
    name: 'ButtonCTA',
    props: {
      cta: String,
      link: {
        type: String,
        default: ''
      },
      href: {
        type: String,
        default: ''
      },
      state: {
        type: String,
        default: 'default'
      },
      showIcon: {
        type: Boolean,
        default: false 
      },
      showRouterButton: {
        type: Boolean,
        default: true
      }
    }
  };
  </script>
  
<style scoped>
/* features we want all buttons to have */
button {
    padding: 0.8vw 1.5vw;
    font-size: 18px;
    border-radius: 16px;
    cursor: pointer;
}

.icon {
  font-size: 25px;
}
/* features we want all different states of button to have */
.default {
    background-color: var(--light-red-color);
    border: 2px solid transparent;
    color: var(--background-color);

}
.default:hover {
    background-color: var(--background-color);
    border: 2px solid var(--black-color);
    color: var(--black-color);
}
.white {
    background-color: var(--background-color);
    border: 2px solid transparent;
    color: var(--black-color);
}
.white:hover {
    background-color: var(--black-color);
    border: 2px solid var(--background-color);
    color: var(--background-color);
}

/* mobile */
@media only screen and (max-width: 768px) {
  button {
    padding: 2vw 5vw;
    font-size: 18px;
}
}
</style>