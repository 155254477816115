<template>
  <div class="h-main-container">
    <div class="l-left-side">
      <div class="list-item" v-for="(demand, index) in demands" :key="index" @click="selectedDemand = index" :class="{ active: index === selectedDemand }">
        <div class="list-title">
          <span><font-awesome-icon :icon="index === selectedDemand ? ['fas', 'fa-file'] : ['far', 'fa-file']" /></span>
        <h3>{{ demand }}</h3>
        </div>
    
        <!-- Render demand text only on mobile -->
        <div class="mobile">
          <p class="line">{{ demandTexts[index] }}</p>
        </div>
      </div>
    </div>
    <!-- Render demand text on desktop -->
    <div class="r-left-side desktop">
      <p class="line">{{ demandText }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ListPopup',
  props: {
    demands: {
      type: Array,
      required: true
    },
    defaultDemand: {
      type: Number,
      default: 0
    },
    demandTexts: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectedDemand: this.defaultDemand
    };
  },
  computed: {
    demandText() {
      return this.demandTexts[this.selectedDemand] || '';
    }
  }
};
</script>

<style scoped>
a {
  text-decoration: none;
}
.list-title {
  display: flex;
  align-items: center;
  gap: 3vw;
}
.list-item {
  padding-bottom: 1vw;
  border-bottom: 1px var(--light-grey-color) solid;
  cursor: pointer;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 2vw;
  align-items: center;
}
.list-item:hover {
  color: var(--light-red-color);
}
.h-main-container {
  justify-self: end;
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  grid-gap: 5vw;
}

/* Media query for mobile */
@media only screen and (max-width: 600px) {
  .desktop {
    display: none;
  }

  .mobile {
    display: none;
  }

 .list-item.active .mobile {
    display: flex;
  }

  .list-item {
  padding-bottom: 1vw;
  border-bottom: 1px var(--light-grey-color) solid;
  cursor: pointer;
  display: grid;
  grid-template-columns: auto  ;
  grid-template-rows: auto auto;
  grid-gap: 1vw;
  align-items: center;
}

.list-item .mobile {
  color:var(--black-color);
  font-family: 'TASA-Text-regular';
}

.h-main-container {
  justify-self: end;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 5vw;
}




}
/* Media query for desktop */
@media only screen and (min-width: 601px) {
  .mobile {
    display: none;
  }
}
</style>
