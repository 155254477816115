<template>
  <div id="app">
    <HeaderMain />
    <!-- we will have our different pages rendered here  -->
    <router-view :key="$route.fullPath"></router-view>
    <FooterMain />
  </div>

</template>


<script>
import HeaderMain from './components/HeaderMain.vue'
import FooterMain from './components/FooterMain.vue'

export default {
  name: 'App',
  components: {
    HeaderMain,
    FooterMain
  }
};
</script>

<style>
@import '@/assets/colors.css';
@import "@/assets/fonts.css";

body {
  background-color: var(--background-color);
  color: var(--black-color);
  font-size: 18px;
  margin: 0;
  font-family: 'TASA-Text-regular';
  line-height: 145%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input,
p {
  font-size: 19px;
}

a {
  color: var(--black-color);
  text-decoration: none;
}


a:hover {
  color: var(--light-red-color);
}

.main-container {
  margin: 0 9vw 3vw 9vw;
  width: 82vw;

}

.main-container.article {
  margin: 3vw 15vw 3vw 15vw;
  width: 70vw;
  display: grid;
  grid-template-columns: 1fr;

}

.main-container.title {
  display: grid;
}

.line {
  border-left: 2px var(--black-color) solid;
  padding-left: 2vw;
}

h1 {
  font-family: 'TASA-Display-black';
  font-size: 71px;
  line-height: 100%;

}

h2 {

  font-family: 'TASA-Display-medium';
  font-size: 41px;
  line-height: 115%;

}

h3 {

  font-family: 'TASA-Display-regular';
  font-size: 28px;
  line-height: 115%;

}

option {
  color: var(--black-color);
}


/* resources */
.tagsBtn {
  width: 30%;
  padding: 1vw 2vw;
  border-radius: 15px;
  border: 1px var(--black-color) solid;
  background-color: var(--background-color);
  font-size: 18px;
  cursor: pointer;
  color: var(--black-color);
}

.tag-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 2vw;
}

/* active */
.resources-pg .active {
  background-color: var(--light-red-color);
  color: var(--background-color);
  border: none;
}

.active {
  color: var(--light-red-color);
  font-family: 'TASA-Display-medium';
}

/* news */
/* tags */
.tags {
  display: flex;
  gap: 2vw;
  margin-bottom: 5vh;
}

.tag {
  padding: 0.4vw 1.5vw;
  width: fit-content;
  border-radius: 16px;
cursor: pointer;
}

.tag.box {
  border: 2px solid var(--background-color);
}
.tag.box:hover  {
  border: 2px solid var(--background-color);
}
.article-box {
  border-radius: 20px;
  height: 100%;
  transition: all 0.3s ease;
  border: 1px solid var(--dark-grey-color);
  background-color: var(--background-color);

}

.article-details {
  display: flex;
  flex-direction: column;
  gap: 1vw;
  padding: 2vw 1vw;
}

.article-image {
  width: 100%;
  height: 40%;
  border-radius: 20px 20px 0 0;
  filter: sepia(100%) hue-rotate(-50deg) saturate(300%) brightness(80%);
}

.articleTitle {
  font-size: 22px;
  font-family: 'TASA-Text-bold';
  margin: 0;
}

.articleTime,
.articleSrc {
  font-size: 18px;
  margin: 0 0 10px 0;
}

.articleTags {

  font-size: 18px;
  gap: 1vw;
  margin-right: 1vw;
}



/* mobile */
@media only screen and (max-width: 768px) {
  h1 {
    font-size: 50px;
  }

  h2 {
    font-size: 31px;
    line-height: 115%;

  }

  p {
    font-size: 16px;
  }

  h3 {
    font-size: 20px;
  }

  .tagsBtn {
    width: 100%;
    font-size: 16px;
    padding: 4vw 2vw;
  }

  .tag {
    padding: 2vw 4vw;
  }

  .article-details {
    gap: 3vw;
    padding: 4vw 3vw;
  }

  .articleTitle {
    font-size: 20px;
  }

  .articleTime,
  .articleSrc {
    font-size: 16px;
  }

  .main-container.article {
  margin: 3vw 9vw 3vw 9vw;
  width: 82vw;
  grid-template-columns:1fr;

}
}
</style>
