<template>
    <div class="articles">
        <div class="search-cont">
            <h2>Articles</h2>
            <input type="text" placeholder="Search...">
        </div>

        <div class="tags">
            <div class="tag box" :style="{ borderColor: getTagColor('Media'), '--tag-hover-color': getTagColor('Media') }">Media</div>
    <div class="tag box" :style="{ borderColor: getTagColor('Protest'), '--tag-hover-color': getTagColor('Protest') }">Protest</div>
    <div class="tag box" :style="{ borderColor: getTagColor('Research'), '--tag-hover-color': getTagColor('Research') }">Research</div>
    </div>

        <div class="articleContainer">
            <div v-for="(article, index) in articleDetails" :key="index">
                <router-link :to="{ name: 'ArticlePage', params: { id: article.id } }">
                <div class="article-box" >
                  
                    <img class="article-image" :src="article.imageSrc" :alt="article.title + ' image'" />
                    <div class="article-details">
                        <div>
                            <span class="articleTags tag" v-for="(tag, tagIndex) in article.articleTags" :key="tagIndex"
                                :style="{ backgroundColor: getTagColor(tag) }">{{ tag }}</span>
                        </div>
                        <div>
                            <p class="articleTime"> {{ article.time }} </p>
                            <p class="articleTitle">{{ article.title }} </p>
                        </div>

                        <p class="articleSrc"> {{ article.src }} </p>

                    </div>
             
                </div>
            </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ArticleGrid",
    methods: {
        getTagColor(tagText) {
            if (tagText === 'Media') {
                return '#8CAEE4';
            } else if (tagText === 'Protest') {
                return '#EE8A86';
            } else if (tagText === 'Research') {
                return '#C2DA86';
            } else {
                return '#5d5a66';
            }
        }
    },
  
        data() {
            return {
                articleDetails: [
                    {
                        id: 0,
                        title: 'TCD students blockade Book of Kells in protest over rising accommodation costs',
                        time: 'Sept. 13 2023',
                        src: 'The Irish Times',
                        imageSrc: 'https://universitytimes.ie/wp-content/uploads/2019/09/Rent-strikes-e1567537237117.jpg',
                        articleTags: ['Media', 'Protest']
                    },
                    {
                        id: 1,
                        title: 'New Study Reveals Impact of Climate Change on Local Ecosystems',
                        time: 'Oct. 5 2023',
                        src: 'National Geographic',
                        imageSrc: 'https://innovationinpolitics.eu/wp-content/uploads/2021/03/climate-change-projects-local-action-1024x682.jpg',
                        articleTags: ['Research']
                    },
                    {
                        id: 2,
                        title: 'Government Announces Funding for Public Transportation Overhaul',
                        time: 'Nov. 20 2023',
                        src: 'CNN',
                        imageSrc: 'https://img2.thejournal.ie/article/6162788/river/?height=400&version=6162931',
                        articleTags: ['Media']
                    },
                    {
                        id: 3,
                        title: 'Exclusive Interview with Award-Winning Author on New Book Release',
                        time: 'Dec. 15 2023',
                        src: 'The New York Times',
                        imageSrc: 'https://universitytimes.ie/wp-content/uploads/2019/09/Rent-strikes-e1567537237117.jpg',
                        articleTags: ['Media', 'Research']
                    },
                    {
                        id: 4,
                        title: 'Local Community Comes Together to Combat Homelessness Crisis',
                        time: 'Jan. 8 2024',
                        src: 'The Guardian',
                        imageSrc: 'https://universitytimes.ie/wp-content/uploads/2019/09/Rent-strikes-e1567537237117.jpg',
                        articleTags: ['Protest']
                    },
                    {
                        id: 5,
                        title: 'Breakthrough Medical Research Offers Hope for Cancer Treatment',
                        time: 'Feb. 22 2024',
                        src: 'Medical News Today',
                        imageSrc: 'https://universitytimes.ie/wp-content/uploads/2019/09/Rent-strikes-e1567537237117.jpg',
                        articleTags: ['Research']
                    },
                ]
            };

        }
    }
</script>

<style scoped>
.articles {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1vw;
    width: 100%;
}

a:hover .article-box {
   color: var(--black-color);
  transform: scale(0.95);
  background-color: var(--light-grey-color);
 
}

.articleContainer {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2vw;
    margin-bottom: 5vh;
}

.tag.box:hover {
  background-color: var(--tag-hover-color); /* Change background color on hover */
}

/* search */
.search-cont {
    margin-top: 10vh;
    display: flex;
    align-items: center;
    gap: 3vw;
    width: 100%;
}

input {
    padding: 0.8vw 1vw;
    border-radius: 21px;
    width: 40%;
    height: fit-content;
    background-color: var(--light-grey-color);
    color: var(--dark-grey-color);
    border: none;
}



@media (max-width: 768px) {
    .search-cont {
        margin: 7vh 0 3vh 0;
        align-items: start;
        flex-direction: column;
        gap: 3vw;

    }

    .search-cont h2 {
        margin: 0;
    }

    input {
        padding: 2vw 4vw;
        width: calc(100% - 8vw);

    }


    .articleContainer {
        grid-template-columns: 1fr;
        gap: 5vw;
    }

   
}
</style>