<template>
    <div class="f-main-container">
        <div class="f-right-side">
            <h2>Together, we’re stronger</h2>
            <p> Register your interest to hear about the Solidarity Network’s campaigns
                and actions. </p>
            <div class="newsletter-cont">
                <input type="email" placeholder="Enter your email" />
                <ButtonCTA cta="" :showIcon="true" link="" state="white" />
            </div>
            <div class="follow">
                <p>Or follow our social media</p>
                <a href="https://www.instagram.com/tcdrentstrike/" target="_blank"><font-awesome-icon
                        :icon="['fab', 'instagram']" /></a>
            </div>
        </div>
        <div class="f-left-side">
            <img src="../assets/footer_copy.svg" alt="">
        </div>
    </div>

</template>
<script>
import ButtonCTA from './ButtonCTA.vue'

export default {
    name: 'FooterMain',
    components: {
        ButtonCTA
    }
}
</script>

<style scoped>
a {
    text-decoration: none;
    color: var(--background-color);
    font-size: 30px;
}

h2 {
    margin: 0;
}

.follow {
    display: flex;
    margin-top: 1vw;
    gap: 2vw;
    align-items: center;
}

.f-main-container {
    width: 91vw;
    height: fit-content;
    display: grid;
    padding: 2vw 9vw;
    padding-right: 0;
    grid-template-columns: 1fr 1.5fr;
    grid-template-rows: auto;
    grid-gap: 2vw;
    align-items: center;
    background-color: var(--black-color);
    color: var(--background-color);
    overflow: hidden;
}

.f-right-side {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

}

.f-left-side {
    display: flex;
    justify-content: flex-end;


}

.newsletter-cont {
    display: grid;
    grid-template-columns: 1fr auto;
    width: 100%;
    align-items: center;
}

input {
    padding: 0.8vw 1vw;
    border-radius: 15px;
    width: 85%;
    background-color: rgba(252, 251, 248, 0.05);
    border: 1px var(--background-color) solid;
    color: var(--background-color);
}
img {
    opacity: 0.5;
}


/* tablet */
@media only screen and (max-width: 1100px) and (min-width: 769px) {

    .f-main-container {
        grid-template-columns: auto 1fr;
        grid-gap: 2vw;
    }

    .f-right-side {
        width: 40vw;

    }

    input {
        padding: 1vw 2vw;

    }

}

/* mobile */
@media only screen and (max-width: 768px) {

    .f-main-container {
        width: 82vw;
        padding: 6vw 9vw;
        padding-right: 9vw;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-gap: 2vw;
    }

    .f-left-side {
        display: none;
    }

    input {
        padding: 2.5vw 3vw;
    }
}
</style>