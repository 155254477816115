<template>
    <div class="banner">
        <div class="banner-content">
            <!-- Repeat banner items to create a loop -->
            <div v-for="(item, index) in bannerItems" :key="index" class="banner-item">
                <div class="banner-item-inner">
                    <span class="banner-icon"><font-awesome-icon :icon="item.icon" /></span>
                    <span class="banner-text">{{ item.text }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HomeBanner',
    data() {
        return {
            bannerItems: [
                { icon: ['fas', 'bell'], text: '80% of student renters find their rent unaffordable' },
                { icon: ['fas', 'bell'], text: '50% of students work to pay rent' },
                { icon: ['fas', 'bell'], text: '71% of those who worked to pay rent indicated it has a negative impact on their education.' }
            ]
        };
    }
};
</script>

<style scoped>
.banner {
    background-color: var(--dark-red-color);
    padding: 3vw 0;
    overflow: hidden;
}

.banner-content {
    color: var(--background-color);
    display: flex;
    animation: marquee 30s linear infinite;
}

.banner-item {
    flex-shrink: 0;
    display: flex;
    gap: 2vw;
    font-size: 30px;
}

.banner-item:last-child {
    margin-right: 0;
}

.banner-item-inner {
    display: flex;
    align-items: center;
}

.banner-text {
    white-space: nowrap;
}

.banner-icon {
    margin: 0 3vw;
}

/* Define the animation */
@keyframes marquee {
    0% {
        transform: translateX(100%);
        /* Start from the right edge */
    }

    100% {
        transform: translateX(-100%);
        /* Move to the left edge */
    }
}

@media only screen and (max-width: 768px) {
    .banner {
        padding: 10vw 0;
    }

    .banner-content {
    animation: marquee 20s linear infinite;
}

@keyframes marquee {
    0% {
        transform: translateX(100%);
        /* Start from the right edge */
    }

    100% {
        transform: translateX(-500%);
        /* Move to the left edge */
    }
}

}
</style>