<template>
    <div class="main-container resources-pg">
        <LandingText title="Resources" subTitle="What do you need to know?"
            txt="The resources below are for current and prospective student renters in Dublin--particularly those renting for the first time or moving to Ireland from abroad.
            We've included information about your Tenant Rights, tips on avoiding scams, infographics on getting around Dublin, and links to external resources."
            picture_src="resourcesPicture.svg" />


        <ImageTags />

        <h2>Cost of Living Calculator</h2>
        <div class="calculator">
            <CostofLiving />
            <div class="info">
                <p class="info-title">How it works?</p>
                <p>
                    This Cost-of-Living calculator is sourced from Numbeo's crowd-sourced databases. The figures estimated here were last updated
                    in April 2024, representing the median price of various Dublin living expenses from over 500 individual contributers. Please note
                    that this calculator offers approximations and your actual rental experience may be more or less costly.
                </p>
                <p>Trinity Renters' Solidarity Network works in service of student renters, and if you feel equally frustrated by Trinity's antiquated policies
                and Dublin's skyrocketing cost-of-living, join us! Your voice and story are critical to ensuring change.  
                </p>

                <a href="">
                    <p class="footnote-sml"> <a href="https://www.numbeo.com/cost-of-living/in/Dublin" target="_blank">More accurate calculation <span class="arr"> ↗ </span> </a></p>
                </a>
            </div>
        </div>

        <h2>Rental Network</h2>
        <LinksNetwork />
    </div>

</template>

<script>
import LinksNetwork from "@/components/LinksNetwork.vue";
import ImageTags from "@/components/ImageTags.vue";
import CostofLiving from "@/components/CostofLiving.vue";
import LandingText from '../components/LandingText.vue';

export default {
    name: 'ResourcesPage',
    components: {
        ImageTags,
        LinksNetwork,
        CostofLiving,
        LandingText
    },
}

</script>

<style scoped>
.calculator {
    display: grid;
    width: 100%;
    grid-template-columns: 2.5fr 1.5fr;
    grid-gap: 5vw;
    margin: 4vw 0;
    align-items: center;
}

.info {
    border-radius: 15px;
    background-color: var(--light-grey-color);
    padding: 3vw;
    color: var(--dark-grey-color);
    height: fit-content;
}

.info p {
    font-size: 16px;
}

.info-title {
    color: var(--black-color);
}

.footnote-sml {
    color: var(--black-color);
    margin-top: 5vh;
    text-align: end;
}

.arr {
    color: var(--dark-red-color);
}

@media only screen and (max-width: 800px) {
    .calculator {
        grid-template-columns: 1fr;
    }
    .info {
    padding: 5vw;
  
}
}
</style>