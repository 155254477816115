<template>
    <div class="h-main-container">
        <router-link to="/rentersnet"><img alt="Vue logo" src="../assets/logo.svg"></router-link>
        <div class="h-right-side desktop">
            <router-link to="/about" :class="{ active: $route.path === '/about' }">About us & Demands</router-link>
            <router-link to="/resources" :class="{ active: $route.path === '/resources' }">Resources</router-link>
            <router-link to="/news" :class="{ active: $route.path === '/news' }">News & Events</router-link>
            <ButtonCTA cta="Take Action" link="/actions" state="default" />
        </div>
        <div class="h-right-side mobile" @click="toggleModal">
            <font-awesome-icon :icon="['fas', 'bars']" />
        </div>
    </div>

    <div class="bread-crumbs">
        <router-link to="/">
            <p>Home</p>
        </router-link>
     
            <p>→</p>
  

        <p style="text-transform: capitalize;">{{ $route.path.substring(1) }}</p>

    </div>
    <div v-if="isModalOpen" class="mobile-modal mobile" @click="toggleModal">
        <router-link to="/about">About us & Demands</router-link>
        <router-link to="/resources">Resources</router-link>
        <router-link to="/news">News & Events</router-link>
        <ButtonCTA cta="Take Action" link="/actions" state="default" />
    </div>

</template>
<script>
import ButtonCTA from './ButtonCTA.vue'

export default {
    name: 'HeaderMain',
    components: {
        ButtonCTA
    },
    data() {
        return {
            isModalOpen: false
        }
    },
    methods: {
        toggleModal() {
            this.isModalOpen = !this.isModalOpen;
            if (this.isModalOpen) {
                // Disable scrolling
                document.body.style.overflowY = 'hidden'; // Only disable vertical scrolling
            } else {
                // Enable scrolling
                document.body.style.overflowY = 'auto'; // Re-enable vertical scrolling
            }
        }
    }
}
</script>

<style scoped>
.h-main-container {
    width: 82vw;
    display: grid;
    padding: 0.5vw 9vw;
    border-bottom: 1px var(--light-grey-color) solid;
    grid-template-columns: auto 2fr;
    grid-template-rows: auto;
    grid-gap: 10vw;
    align-items: center;
}

.h-main-container img {
    justify-self: start;
    width: 70px;
}

.h-right-side {
    justify-self: end;
    align-items: center;
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-template-rows: auto;
    grid-gap: 2vw;
}

.bread-crumbs {
    width: 82vw;
    display: grid;
    padding: 0.3vw 9vw;
    border-bottom: 1px var(--light-grey-color) solid;
    grid-template-columns: auto auto auto 2fr;
    grid-template-rows: auto;
    grid-gap: 2vw;
    align-items: center;
    line-height: 0 !important;
}

.mobile {
    display: none;
}

.active {
    color: var(--light-red-color);
    font-weight: bold;
}

/* Mobile modal styles */
.mobile-modal {
    position: fixed;
    margin-top: 0;
    left: 0;
    width: 82vw;
    height: 85vh;
    background-color: var(--background-color);
    padding: 20vh 9vw;
    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 3vh;
    z-index: 999;
    /* Ensure it's above other content */
}



/* Show mobile modal when open */
.mobile-modal.open {
    display: block;
}


/* tablet and mobile */
@media only screen and (max-width: 850px) {

    .h-main-container {
        grid-gap: 2vw;
    }

    .h-right-side {
        grid-template-columns: 1fr;
        grid-gap: 0;

    }

    .desktop {
        display: none;
    }

    .mobile {
        display: flex;
    }

}
</style>