<template>
    <div class="outlined-card">
        <div class="icon">
            <span><font-awesome-icon
                    :icon="index === selectedDemand ? ['fas', 'diamond'] : ['far', 'diamond']" /></span>
        </div>
        <div class="text">
            <h2 class="title">{{ title }}</h2>
            <p class="small-text">{{ smallText }}</p>
            <img v-if="picture_src" :src="resolve_img_url(picture_src)" :alt="altText">
            <ButtonCTA class="button" :cta="buttonText" :link="buttonLink" :showRouterButton="true" />
        </div>
    </div>
</template>

<script>
import ButtonCTA from './ButtonCTA.vue'

export default {
    props: {
        title: String,
        smallText: String,
        buttonText: String,
        buttonLink: String,
        picture_src: {
            type: String,
            default: null
        },
        altText: String
    },
    components: {
        ButtonCTA
    },
    methods: {
        resolve_img_url: function (path) {
            let images = require.context('../assets/', false)
            return images("./" + path)
        }
    }
};
</script>

<style scoped>
.outlined-card {
    border: 1px solid #A7A6A5;
    border-radius: 16px;
    padding: 3vh;
    display: flex;
}


.icon {
    margin-right: 15px;
    padding-top: 5px;
    font-size: 35px;
    color: #D40015;
}

.title {
    margin: 0;
}

.small-text {
    margin-top: 5px;
}
img {
   width: 30%;
   align-self: center;
}
.text {
    display: flex;
    gap: 2vh;
    flex-direction: column;
justify-content: space-between;
}
.button {
    align-self: end;
}


@media only screen and (max-width: 600px) {
 
    .icon {
        padding-top: 2px;
        font-size: 24px;
    }

    .title {
        font-size: 24px;
    }

    .small-text {
        font-size: 19px;
    }
    img {
   width: 100%;
}
}
</style>
