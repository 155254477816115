<template>
  <div class="cost-calculator">
    <div class="questions">

      <div class="question" v-for="(options,question) in questions"
      :key="question">

      <div class="question-table">
      <label :for="question">{{ question }}</label>
      <select :id="question" v-model="answers[question]">
        <option v-for="option in options"
        :key="option.cost"
        :cost="option.cost">{{ option.text }}</option>
      </select>
      </div>

  
      </div>
    </div>

    <div class="number-display">
      <p class="footnote-sml">Estimated monthly cost</p>
      <div class="total">{{ totalCost }}</div>
    </div>
    <p class="footnote-sml">&#42; Based on 2024 data on Numbeo</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      answers: {
        accommodation:'',
        cook:''},
      
      questions:{
        'What type of accommodation do you plan to live in?':[
          { text:'On Campus', cost:1000 },
          { text:'Digs', cost: 700 },
          { text:'Student Accommodation', cost:1100 },
          { text: "Room in Shared Apartment", cost: 900 },
          { text: "Bed in Shared room", cost: 500 },
          { text: "Studio", cost: 1500}
        ],
        'How often do you cook?':[
          { text: "Every meal", cost: 200 },
          { text: "Half the times", cost: 400 },
          { text: "Every other day", cost: 500 },
          { text: "Never", cost: 600 }
        ],
        'How often do you spend on cigarette/alcohol/coffee?':[
          { text: "Barely not", cost: 0 },
          { text: "less than 3 times a week", cost: 50 },
          { text: "Every other day", cost: 100 },
          { text: "Everyday", cost: 200 }
        ],
        'Do you live with dependent kids or pet?':[
          { text: "No", cost: 0 },
          { text: "Yes, one pet", cost: 50 },
          { text: "Yes, one kid", cost: 600 },
        ],
        'Do you live with your partner?':[
          { text: "No", cost: 0 },
          { text: "Yes", cost: -400 },
        ],
        'How do you plan to commute?':[
          { text: "By walk or cycle", cost: 0 },
          { text: "By bus/trams/rail", cost: 40 },
          { text: "By car", cost: 200 },
        ],
        }};
        },
  computed: {
      totalCost() {
        return Object.keys(this.questions).reduce((total, question) => {
          const selectedOptionText = this.answers[question];
          const questionOptions = this.questions[question];
          const selectedOption = questionOptions.find(option => option.text === selectedOptionText);
          return total + (selectedOption ? selectedOption.cost : 0);
        }, 0);
      },
  }
};
</script>

<style scoped>
.questions {
  display: flex;
  flex-direction: column;
  gap:31px;
}

.question-table {
  display:grid;
  grid-template-columns: 2fr 0.6fr;
  grid-template-rows: auto;
  width: 100%;
}

label {
  border-left: 1px solid var(--light-red-color);
  padding-left: 2vw;
  font-size: 20px;
}

select {
  border: none;
  border-bottom: 1px solid var(--black-color); /* Your border bottom style */
  background-color: var(--background-color); /* Your background color */
  width: 100%;
  border-radius: 0;
  font-size: 18px; /* Your font size */
  -webkit-appearance: none; /* Override Safari's default styling */
  -moz-appearance: none; /* Override Firefox's default styling (optional) */
  appearance: none; /* Override default styling for other browsers */
}



.number-display{
  text-align: right;
  padding: 3vw;
  margin-top: 5vh;
  border: 2px solid var(--light-red-color);
  border-radius: 15px;
}

.cost-calculator{
  display: flex;
  flex-direction: column;
  height: fit-content;
  margin-top: 5vh;
}

.footnote-sml {
  color: var(--dark-grey-color);
  padding-top:2vh;
}

.total{
  font-size: 45px;
  font-family: 'TASA-Display-medium';
  padding-top: 3vh;
}

@media only screen and (max-width: 800px) {
  .question-table {
  grid-template-columns: 1fr;
 
}

label {
  font-size: 18px;
}

select {
margin-top:3vh;
}
}

</style>