<template>
    <div class="main-container article">
        <div class="article-detail">
            <p>{{ article.time }}</p>
            <span class="articleTags tag" v-for="(tag, tagIndex) in article.articleTags" :key="tagIndex"
                :style="{ backgroundColor: getTagColor(tag) }">{{ tag }}</span>
            <h2> {{ article.title }}</h2>
            <p>{{ article.src }}</p>
            <img class="article-image-open" :src="article.imageSrc" :alt="article.title + ' image'" />
            <div class="article-details">

            </div>
            <div class="article-text">
            <h3>{{ article.subTitle }}</h3>
            <p> {{ article.text }}</p>
        </div>
        </div>

     <!-- <div class="small-article-grid">
        hello
     </div> -->

   
    </div>

</template>

<script>


export default {
    name: 'ArticlePage',
    props: ['id'],
    created() {
        this.fetchArticle();
    },
    methods: {
        fetchArticle() {
            const id = parseInt(this.$route.params.id);
            this.article = this.articleDetails.find(article => article.id === id);
        },

        getTagColor(tagText) {
            if (tagText === 'Media') {
                return '#8CAEE4';
            } else if (tagText === 'Protest') {
                return '#EE8A86';
            } else if (tagText === 'Research') {
                return '#C2DA86';
            } else {
                return '#5d5a66';
            }
        }
    },
   
    data() {
        return {
            articleDetails: [
                {
                    id: 0,
                    title: 'TCD students blockade Book of Kells in protest over rising accommodation costs',
                    time: 'Sept. 13 2023',
                    src: 'The Irish Times',
                    imageSrc: 'https://universitytimes.ie/wp-content/uploads/2019/09/Rent-strikes-e1567537237117.jpg',
                    articleTags: ['Media', 'Protest'],
                    subTitle: 'Foreign tourists unable to visit top Dublin attraction express disappointment but sympathy with housing demonstration',
                    text: 'Students blockaded the entrance to the Book of Kells exhibition at Trinity College Dublin on Wednesday in protest against a two per cent rise in accommodation prices on campus. Around 30 students blocked the entry to the tourist attraction, with placards stating “students not cash cows” and “freeze rent”. In a statement, the college’s student union said that Trinity College “make €10 million a year” from their student accommodation, adding “if they take money from the students, then the students will take money from college, by blockading one of Trinity’s most profitable attractions”. Trinity College said that, in light of persistently high inflation, “modest increases in rents” have been required to cover rising costs.'
                },
                {
                    id: 1,
                    title: 'New Study Reveals Impact of Climate Change on Local Ecosystems',
                    time: 'Oct. 5 2023',
                    src: 'National Geographic',
                    imageSrc: 'https://innovationinpolitics.eu/wp-content/uploads/2021/03/climate-change-projects-local-action-1024x682.jpg',
                    articleTags: ['Research'],
                    subTitle: 'Foreign tourists unable to visit top Dublin attraction express disappointment but sympathy with housing demonstration',
                    text: 'Students blockaded the entrance to the Book of Kells exhibition at Trinity College Dublin on Wednesday in protest against a two per cent rise in accommodation prices on campus. Around 30 students blocked the entry to the tourist attraction, with placards stating “students not cash cows” and “freeze rent”. In a statement, the college’s student union said that Trinity College “make €10 million a year” from their student accommodation, adding “if they take money from the students, then the students will take money from college, by blockading one of Trinity’s most profitable attractions”. Trinity College said that, in light of persistently high inflation, “modest increases in rents” have been required to cover rising costs.'
               
                },
                {
                    id: 2,
                    title: 'Government Announces Funding for Public Transportation Overhaul',
                    time: 'Nov. 20 2023',
                    src: 'CNN',
                    imageSrc: 'https://img2.thejournal.ie/article/6162788/river/?height=400&version=6162931',
                    articleTags: ['Media'],
                    subTitle: 'Foreign tourists unable to visit top Dublin attraction express disappointment but sympathy with housing demonstration',
                    text: 'Students blockaded the entrance to the Book of Kells exhibition at Trinity College Dublin on Wednesday in protest against a two per cent rise in accommodation prices on campus. Around 30 students blocked the entry to the tourist attraction, with placards stating “students not cash cows” and “freeze rent”. In a statement, the college’s student union said that Trinity College “make €10 million a year” from their student accommodation, adding “if they take money from the students, then the students will take money from college, by blockading one of Trinity’s most profitable attractions”. Trinity College said that, in light of persistently high inflation, “modest increases in rents” have been required to cover rising costs.'
               
                },
                {
                    id: 3,
                    title: 'Exclusive Interview with Award-Winning Author on New Book Release',
                    time: 'Dec. 15 2023',
                    src: 'The New York Times',
                    imageSrc: 'https://universitytimes.ie/wp-content/uploads/2019/09/Rent-strikes-e1567537237117.jpg',
                    articleTags: ['Media', 'Research'],
                    subTitle: 'Foreign tourists unable to visit top Dublin attraction express disappointment but sympathy with housing demonstration',
                    text: 'Students blockaded the entrance to the Book of Kells exhibition at Trinity College Dublin on Wednesday in protest against a two per cent rise in accommodation prices on campus. Around 30 students blocked the entry to the tourist attraction, with placards stating “students not cash cows” and “freeze rent”. In a statement, the college’s student union said that Trinity College “make €10 million a year” from their student accommodation, adding “if they take money from the students, then the students will take money from college, by blockading one of Trinity’s most profitable attractions”. Trinity College said that, in light of persistently high inflation, “modest increases in rents” have been required to cover rising costs.'
               
                },
                {
                    id: 4,
                    title: 'Local Community Comes Together to Combat Homelessness Crisis',
                    time: 'Jan. 8 2024',
                    src: 'The Guardian',
                    imageSrc: 'https://universitytimes.ie/wp-content/uploads/2019/09/Rent-strikes-e1567537237117.jpg',
                    articleTags: ['Protest'],
                    subTitle: 'Foreign tourists unable to visit top Dublin attraction express disappointment but sympathy with housing demonstration',
                    text: 'Students blockaded the entrance to the Book of Kells exhibition at Trinity College Dublin on Wednesday in protest against a two per cent rise in accommodation prices on campus. Around 30 students blocked the entry to the tourist attraction, with placards stating “students not cash cows” and “freeze rent”. In a statement, the college’s student union said that Trinity College “make €10 million a year” from their student accommodation, adding “if they take money from the students, then the students will take money from college, by blockading one of Trinity’s most profitable attractions”. Trinity College said that, in light of persistently high inflation, “modest increases in rents” have been required to cover rising costs.'
               
                },
                {
                    id: 5,
                    title: 'Breakthrough Medical Research Offers Hope for Cancer Treatment',
                    time: 'Feb. 22 2024',
                    src: 'Medical News Today',
                    imageSrc: 'https://universitytimes.ie/wp-content/uploads/2019/09/Rent-strikes-e1567537237117.jpg',
                    articleTags: ['Research'],
                    subTitle: 'Foreign tourists unable to visit top Dublin attraction express disappointment but sympathy with housing demonstration',
                    text: 'Students blockaded the entrance to the Book of Kells exhibition at Trinity College Dublin on Wednesday in protest against a two per cent rise in accommodation prices on campus. Around 30 students blocked the entry to the tourist attraction, with placards stating “students not cash cows” and “freeze rent”. In a statement, the college’s student union said that Trinity College “make €10 million a year” from their student accommodation, adding “if they take money from the students, then the students will take money from college, by blockading one of Trinity’s most profitable attractions”. Trinity College said that, in light of persistently high inflation, “modest increases in rents” have been required to cover rising costs.'
               
                },
            ]
        };

    }

}
</script>

<style scoped>
.article-detail {
    width: 100%;
}

.article-image-open {
    width: 100%;
    border-radius: 16px;
}
</style>