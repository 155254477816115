<template>
    <div class="main-container">
        <LandingText title="Actions" subTitle="What can I do?"
            txt="Support your rights, your community, and ensure your voice is heard! Engage with the Trinity Renters' Solidarity Network in-person at one of our events below, or engage with us online! There are lots of ways to join our student network and become actively involved in your renter space. Read on for more."
            picture_src="action(red).svg" altText="line illustration rent strike" class="action" />


        <PetitionCard title="Latest petition" subTitle="Trinity - Abolish the Overnight Guests Policy!"
            txt="The accommodation Trinity so graciously provides its students is overpriced and dingy, with terrible WiFi, frequently malfunctioning utilities and an almost non-existent maintenance system. On top of the conditions in these flats, we as residents are subjected to arbitrary limitations on our autonomy and personal lives such as the overnight guests policy."
            picture_src="rentstrike8.png" signaturesGoal="600" signaturesLeft="554" buttonCtaText="Sign here"
            buttonCtaHref="https://my.uplift.ie/petitions/trinity-abolish-the-overnight-guests-policy" />


        <div class="action-cards-container">
            <ActionCard title="Overnight Guest Policy" tag="Petition" buttonCtaText="Learn more"
                text="Integer vitae iaculis odio. Integer id nibh nec diam consequat venenatis at id venenatis at id
            Integer vitae iaculis odio. Integer id nibh nec diam consequat venenatis at id venenatis at idInteger vitae iacul Integer vitae iaculis odio. Integer id nibh nec diam consequat venenatis at id venenatis at id
            Integer vitae iaculis odio. Integer id nibh nec diam consequat venenatis at id venenatis at idInteger vitae iacul" buttonText="Go to Petition" />
            <ActionCard title="Rent Strike" tag="Event" buttonCtaText="Learn more"
                text="Integer vitae iaculis odio. Integer id nibh nec diam consequat venenatis at id venenatis at id
            Integer vitae iaculis odio. Integer id nibh nec diam consequat venenatis at id venenatis at idInteger vitae iacul Integer vitae iaculis odio. Integer id nibh nec diam consequat venenatis at id venenatis at id
            Integer vitae iaculis odio. Integer id nibh nec diam consequat venenatis at id venenatis at idInteger vitae iacul" buttonText="Sign Up" />
        </div>


        <VentYourRentCard :title="'Vent Your Rent'"
            text="What do you think about your accommodation? Speak up through our online survey and help us research student housing."
            buttonCtaText="Take Survey" buttonCtaHref="https://docs.google.com/forms/d/e/1FAIpQLSf6LspWwrxWiaTGDAxq-_5mdgZra7aG7S1A3DyShMoH_UMcdg/viewform" />


    </div>

</template>

<script>
import LandingText from '../components/LandingText.vue';
import PetitionCard from '../components/PetitionCard.vue'
import ActionCard from '../components/ActionCard.vue';
import VentYourRentCard from '../components/VentYourRent.vue'

export default {
    name: 'ActionPage',
    components: {
        LandingText,
        ActionCard,
        VentYourRentCard,
        PetitionCard
    },
    data() {
        return {
            homepage: true,
        };
    }
};

</script>

<style scoped>
.action-cards-container {
    display: grid;
grid-template-columns: 1fr 1fr;
    margin-top: 10vh;
    grid-gap: 3vw;
}


@media only screen and (max-width: 768px) {
    .action-cards-container {
grid-template-columns: 1fr;
    margin-top: 10vh;
    grid-gap: 6vw;
}
}
</style>